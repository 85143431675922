import * as schema from '@/bundles/schema/typescript/schema';

// apiから受け取る想定のデータのダミー
export const dummyCloud: schema.V1ObjectsXStorage[] = [
    {
        xstorageId: '3001',
        service: schema.V1ObjectsServiceEnum.Box,
        authorizationId: 'abc@gmailcom',
        authorizationDeadline: '2018-01-01',
        authorizationLink: '',
        imageLink: '',
        status: schema.V1ObjectsXStorageStatus.NotAuthored,
        share: 1,
    },
    {
        xstorageId: '3002',
        service: schema.V1ObjectsServiceEnum.Googledrive,
        authorizationId: 'def@gmailcom',
        authorizationDeadline: '2020-01-01',
        authorizationLink: '',
        imageLink: '',
        status: schema.V1ObjectsXStorageStatus.NotAuthored,
        share: 1,
    },
    {
        xstorageId: '3003',
        service: schema.V1ObjectsServiceEnum.Googleteamdrive,
        authorizationId: 'sample@gmailcom',
        authorizationDeadline: '2019-01-01',
        authorizationLink: '',
        imageLink: '',
        status: schema.V1ObjectsXStorageStatus.NotAuthored,
        share: 1,
    },
];

export default dummyCloud;

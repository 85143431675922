import { useState } from 'react';
import { createContainer } from 'unstated-next';
import moment from 'moment';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import * as fileNameModel from '@/user/models/endpoints/fileName/fileName';
import { nameTag, DateFormatValue, forbiddenWords, FOLDER_SIZE, WORDBOOK_SIZE, sequenceType, dateSeparate, DateSeparateFormatValue, DEFAULT_DATE_SEPARATOR } from '@/user/constants/endpoint-setting';
import { Variants } from '@/common/components/messages/CommonMessage';
import { UserAppContainer } from '../../UserAppContainer';
import { default as UI } from '@/common/constants/ui';
import * as errorHandler from '@/common/utils/errorHandler';
import { DateFormat } from '@/common/constants/dateFormat';
import { getEnumData, isNotGarble, getStringByteCount } from '@/common/utils/webappUtil';
import routes from '@/user/constants/routes';
import { validateNameRuleChip, validateFileName } from './namerule/validateNameRule';
import { useHistory } from 'react-router-dom';

const getDateFormat = (dateFormat: string) => {
    switch (dateFormat) {
        case DateFormatValue.Date2: {
            return DateFormat.fullDateWithHyphen;
        }
        case DateFormatValue.Date3: {
            return DateFormat.fullYearMonthTime;
        }
        case DateFormatValue.Date4: {
            return DateFormat.fullYearDayTime;
        }
        case DateFormatValue.Date5: {
            return DateFormat.fullYearMonthWithHyphen;
        }
        case DateFormatValue.Date6: {
            return DateFormat.fullYearDateWithHyphen;
        }
        case DateFormatValue.Date7: {
            return DateFormat.fullYearTime;
        }
        case DateFormatValue.Date8: {
            return DateFormat.fullYear;
        }
        case DateFormatValue.Date9: {
            return DateFormat.fullMonthDayTime;
        }
        case DateFormatValue.Date10: {
            return DateFormat.fullMonthDateWithHyphen;
        }
        case DateFormatValue.Date11: {
            return DateFormat.fullMonthTime;
        }
        case DateFormatValue.Date12: {
            return DateFormat.fullMonth;
        }
        case DateFormatValue.Date13: {
            return DateFormat.fullDayTime;
        }
        case DateFormatValue.Date14: {
            return DateFormat.fullDate;
        }
        case DateFormatValue.Date15: {
            return DateFormat.fullTime;
        }
        default: {
            return DateFormat.fullDateTimeWithHyphen;
        }
    }
};

export const SeparateDateFormat = (dateFormat: DateFormatValue) => {
    switch (dateFormat) {
        case DateFormatValue.Date1:
            return DateSeparateFormatValue.Date1;
        case DateFormatValue.Date2:
            return DateSeparateFormatValue.Date2;
        case DateFormatValue.Date3:
            return DateSeparateFormatValue.Date3;
        case DateFormatValue.Date4:
            return DateSeparateFormatValue.Date4;
        case DateFormatValue.Date5:
            return DateSeparateFormatValue.Date5;
        case DateFormatValue.Date6:
            return DateSeparateFormatValue.Date6;
        case DateFormatValue.Date7:
            return DateSeparateFormatValue.Date7;
        case DateFormatValue.Date8:
            return DateSeparateFormatValue.Date8;
        case DateFormatValue.Date9:
            return DateSeparateFormatValue.Date9;
        case DateFormatValue.Date10:
            return DateSeparateFormatValue.Date10;
        case DateFormatValue.Date11:
            return DateSeparateFormatValue.Date11;
        case DateFormatValue.Date12:
            return DateSeparateFormatValue.Date12;
        case DateFormatValue.Date13:
            return DateSeparateFormatValue.Date13;
        case DateFormatValue.Date14:
            return DateSeparateFormatValue.Date14;
        case DateFormatValue.Date15:
            return DateSeparateFormatValue.Date15;
        default:
            return DateSeparateFormatValue.Date1;
    }
};

export const fileNameDateExample = (dateSeparatorSelected: string, dateFormat: DateFormatValue, now: moment.Moment) => {
    let tempDate = now.format(getDateFormat(dateFormat));
    switch (dateSeparatorSelected) {
        case dateSeparate.NONE:
            tempDate = tempDate.replace(/-/g, '');
            tempDate = tempDate.replace(' ', '');
            tempDate = tempDate.replace(/:/g, '');
            break;
        case dateSeparate.DOT:
        case dateSeparate.HYPHEN:
        case dateSeparate.UNDERSCORE:
            tempDate = tempDate.replace(/-/g, dateSeparatorSelected);
            tempDate = tempDate.replace(' ', dateSeparatorSelected);
            tempDate = tempDate.replace(/:/g, '');
            break;
        case dateSeparate.C:
            tempDate = now.format(SeparateDateFormat(dateFormat));
            break;
    }
    return tempDate;
};

const useFileNameContainer = () => {
    const regexFolder = new RegExp(`^${locale.t(locale.keys.fileName.folderLabel.index)}([1-9]|1[0-9]|20)$`);
    const regexSpecialCharacter = new RegExp(/^(?!.*\.{2})[^\\"<>|/:;*?,¥]*$/);
    const regexSpecialCharacterForMultipleInput = new RegExp(/^(?!.*\.{2})[^\\"<>|/:;*?,¥]*$/);
    const history = useHistory();
    const [ui, setUI] = useState(UI.state.Loading);
    const [endpointId, setEndpointId] = useState('');
    const itemData: string[] = [];
    const [itemList, setItemList] = useState(itemData);
    const [wordListName01, setWordListName01] = useState('');
    const [wordListName02, setWordListName02] = useState('');
    const wordData: string[] = [];
    const [wordList, setWordList] = useState(wordData);
    const [wordList02, setWordList2] = useState(wordData);
    const [dateFormat, setDateFormat] = useState(DateFormatValue.Date1);
    const [separatorSelected, setSeparatorSelected] = useState('');
    const [dateSeparatorSelected, setDateSeparatorSelected] = useState<string>(DEFAULT_DATE_SEPARATOR);
    const [canEmpty, setCanEmpty] = useState(false);
    const [dateFlag, setDateFlag] = useState(false);
    const [userFlag, setUserFlag] = useState(false);
    const [anyCharaterFlag, setAnyCharaterFlag] = useState(false);
    const [folderFlag, setFolderFlag] = useState(false);
    const [isFolderFull, setIsFolderFull] = useState(false);
    const appContainer = UserAppContainer.useContainer();
    // 名付けルール定義のエラーメッセージ出し分けのために使用
    const [fileNameErrorMessage, setFileNameErrorMessage] = useState('');
    const [fileNameLimitErrorMessege, setfileNameLimitErrorMessege] = useState('');
    const [errors, setErrors] = useState({
        wordlist: '',
        wordlist2: '',
    });
    const [errorsName, setErrorsName] = useState({
        wordlistname: '',
        wordlistname2: '',
    });
    const [isEdit, setIsEdit] = useState(false);
    const itemFolder: number[] = [];
    const [folderNumber, setFolderNumber] = useState(itemFolder);
    const [itemSelected, setItemSelected] = useState(-1);
    const [isYearSelected, setIsYearSelected] = useState(false);
    const [isMonthSelected, setIsMonthSelected] = useState(false);
    const [isDaySelected, setIsDaySelected] = useState(false);
    const [isHourSelected, setIsHourSelected] = useState(false);
    const [open, setOpen] = useState(false);

    const [isLock, setIsLock] = useState(false);

    const [isOrgLock, setIsOrgLock] = useState(false);

    const [sequenceTypeSelected, setSequenceTypeSelected] = useState(sequenceType.PUT);
    const [isCopied, setIsCopied] = useState(false);

    const checkInvalidWord = (chip: string) => {
        for (let index = 0; index < forbiddenWords.length; index += 1) {
            if (chip.toLowerCase().indexOf(forbiddenWords[index]) === 0) {
                return true;
            }
        }
        return false;
    };
    const removeUnnecessarySpace = (chip: string) => {
        return chip.replace(/  +/g, ' ');
    };
    const handleAddItem = (chip: string) => {
        const { itemList: validatedItemList, errorMessege } = validateNameRuleChip(chip, itemList);
        setItemList(validatedItemList);
        setfileNameLimitErrorMessege(errorMessege);
        setIsEdit(true);
    };

    const handleDeleteItem = (data: string, index: number) => {
        let dataList = [...itemList];
        const folderList = [...folderNumber];
        dataList = dataList.filter((it: string) => data !== it);
        while (0 < dataList.length && checkInvalidWord(dataList[0])) {
            dataList.shift();
        }
        setItemList(dataList);
        if (data === locale.t(locale.keys.fileName.date.index)) {
            setDateFlag(false);
            setDateFormat(DateFormatValue.Date1);
        }
        if (data === locale.t(locale.keys.fileName.user.index)) {
            setUserFlag(false);
        }
        if (data === locale.t(locale.keys.fileName.anyCharater.index)) {
            setAnyCharaterFlag(false);
        }
        if (data.includes(locale.t(locale.keys.fileName.folderLabel.index))) {
            const numberValue = +data.split(locale.t(locale.keys.fileName.folderLabel.index))[1];
            const index = folderList.indexOf(numberValue);
            folderList.splice(index, 1);
        }

        // 固定文字の場合
        let fixedItemNum = 0;
        for (let i = 0; i < dataList.length; i++) {
            if (
                !regexFolder.test(dataList[i]) &&
                dataList[i] !== locale.t(locale.keys.fileName.anyCharater.index) &&
                dataList[i] !== locale.t(locale.keys.fileName.user.index) &&
                dataList[i] !== locale.t(locale.keys.fileName.time.index) &&
                dataList[i] !== locale.t(locale.keys.fileName.date.index)
            ) {
                fixedItemNum++;
            }
        }

        let selectedItem = itemSelected;
        if (selectedItem === index) {
            selectedItem = -1;
        }
        if (selectedItem > index) {
            selectedItem -= 1;
        }

        // 固定文字が20以下だった場合にエラーメッセージを消去
        // 基本的にアクションがあった場合は削除する方向
        if (fixedItemNum <= FOLDER_SIZE) {
            setfileNameLimitErrorMessege('');
        }
        setFolderNumber(folderList);
        setItemSelected(selectedItem);
        setIsEdit(true);
    };

    // 固定文字が上限の20個を超えたときのエラーメッセージを削除する
    const handleDeleteFileNameLimitErrorMessege = () => {
        setfileNameLimitErrorMessege('');
    };

    // 単語帳1の名称変更
    const handleChangeWordListName01 = (wordListName: string) => {
        // 未入力
        if (!wordListName) {
            setErrorsName({
                wordlistname: locale.t(locale.keys.fileName.workBook.noName),
                wordlistname2: errorsName.wordlistname2,
            });
            // 32バイト以上
        } else if (getStringByteCount(wordListName) > 32) {
            setErrorsName({
                wordlistname: locale.t(locale.keys.validation.tooLongByte, { num: 32 }),
                wordlistname2: errorsName.wordlistname2,
            });
            // SJISで表示できない文字をチェック
        } else if (!isNotGarble(wordListName)) {
            setErrorsName({
                wordlistname: locale.t(locale.keys.fileName.workBook.unavailable),
                wordlistname2: errorsName.wordlistname2,
            });
        } else {
            setErrorsName({
                wordlistname: '',
                wordlistname2: errorsName.wordlistname2,
            });
        }
        setWordListName01(wordListName);
    };

    // 単語帳2の名称変更
    const handleChangeWordListName02 = (wordListName: string) => {
        // 未入力
        if (!wordListName) {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: locale.t(locale.keys.fileName.workBook.noName),
            });
            // 32バイト以上
        } else if (getStringByteCount(wordListName) > 32) {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: locale.t(locale.keys.validation.tooLongByte, { num: 32 }),
            });
            // SJISで表示できない文字をチェック
        } else if (!isNotGarble(wordListName)) {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: locale.t(locale.keys.fileName.workBook.unavailable),
            });
        } else {
            setErrorsName({
                wordlistname: errorsName.wordlistname,
                wordlistname2: '',
            });
        }
        setWordListName02(wordListName);
    };

    const handleAddWord = (chip: string, isWordbook: boolean = false) => {
        let dataList = [...wordList];
        if (isWordbook) {
            dataList = [];
        }
        if (!isNotGarble(chip)) {
            return;
        }
        if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacter.test(chip.trim()) && !dataList.includes(removeUnnecessarySpace(chip.trim()))) {
            dataList.push(removeUnnecessarySpace(chip.trim()));
        }
        let chipList: string[] = [];
        if (chip.includes(',')) {
            chipList = [...chip.split(',')];
        }
        for (const i in chipList) {
            let isPass = false;
            if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacterForMultipleInput.test(removeUnnecessarySpace(chipList[i].trim())) && chipList[i].trim() !== '') {
                isPass = true;
            }
            if (isPass && !dataList.includes(removeUnnecessarySpace(chipList[i].trim()))) {
                dataList.push(removeUnnecessarySpace(chipList[i].trim()));
            }
        }
        if (WORDBOOK_SIZE <= dataList.length) {
            setErrors({
                wordlist: locale.t(locale.keys.validation.wordlist),
                wordlist2: errors.wordlist2,
            });
        }
        setWordList(dataList);
        setIsEdit(true);
    };

    const handleAddWord2 = (chip: string, isWordbook: boolean = false) => {
        let dataList = [...wordList02];
        if (isWordbook) {
            dataList = [];
        }
        if (!isNotGarble(chip)) {
            return;
        }
        if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacter.test(chip.trim()) && !dataList.includes(removeUnnecessarySpace(chip.trim()))) {
            dataList.push(removeUnnecessarySpace(chip.trim()));
        }
        let chipList: string[] = [];
        if (chip.includes(',')) {
            chipList = [...chip.split(',')];
        }
        for (const i in chipList) {
            let isPass = false;
            if (dataList.length < WORDBOOK_SIZE && regexSpecialCharacterForMultipleInput.test(removeUnnecessarySpace(chipList[i].trim())) && chipList[i].trim() !== '') {
                isPass = true;
            }
            if (isPass && !dataList.includes(removeUnnecessarySpace(chipList[i].trim()))) {
                dataList.push(removeUnnecessarySpace(chipList[i].trim()));
            }
        }
        if (WORDBOOK_SIZE <= dataList.length) {
            setErrors({
                wordlist: errors.wordlist,
                wordlist2: locale.t(locale.keys.validation.wordlist),
            });
        }
        setWordList2(dataList);
        setIsEdit(true);
    };

    const handleDeleteWord = (data: string) => {
        setErrors({
            wordlist: '',
            wordlist2: errors.wordlist2,
        });
        let dataList = [...wordList];
        dataList = dataList.filter((it: string) => data !== it);
        setWordList(dataList);
        setIsEdit(true);
    };

    const handleDeleteWord2 = (data: string) => {
        setErrors({
            wordlist: errors.wordlist,
            wordlist2: '',
        });
        let dataList = [...wordList02];
        dataList = dataList.filter((it: string) => data !== it);
        setWordList2(dataList);
        setIsEdit(true);
    };

    const handleChangeCanEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCanEmpty(event.target.checked);
        setIsEdit(true);
    };

    const handleChangeSeparatorSelected = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setSeparatorSelected(event.target.value);
        setIsEdit(true);
    };

    const handleChangeDateSeparatorSelected = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setDateSeparatorSelected(event.target.value);
        setIsEdit(true);
    };

    const handleChangeSequenceTypeSelected = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
        setSequenceTypeSelected(+event.target.value);
        setIsEdit(true);
    };

    const buttonDisabled = () => {
        // falseなら変更を保存ボタン活性化
        let error = false;
        setFileNameErrorMessage('');
        if (!wordListName01 || !wordListName02) error = true;
        if (!(errorsName.wordlistname === '') || !(errorsName.wordlistname2 === '')) error = true;
        if (sequenceTypeSelected === sequenceType.COUNT && !anyCharaterFlag) {
            setFileNameErrorMessage(locale.t(locale.keys.fileName.selectedConditionsErrorMessage.index));
            error = true;
        }
        // ファイル名が不正だったら非活性
        if (!validateFileName(getFileNameExample())) {
            setFileNameErrorMessage(locale.t(locale.keys.fileName.fileNameErrorMessage.index));
            error = true;
        }
        if (itemList.length === 0) {
            setFileNameErrorMessage(locale.t(locale.keys.validation.required));
            error = true;
        }
        return error;
    };

    const loadDummyData = (dummyNameRuleList: schema.V1EndpointsRulesUpdateResponse) => {
        setDateFormat(getEnumData(dummyNameRuleList['dateFormat'], DateFormatValue));
        setCanEmpty(dummyNameRuleList['fileOptional']);
        const itemListString = dummyNameRuleList['itemList'];
        setSeparatorSelected(dummyNameRuleList['separator']);
        setWordList(dummyNameRuleList['wordList']);
        const itemArr: string[] = itemListString.split('|');
        for (const i in dummyNameRuleList['fixed']) {
            const index = itemArr.indexOf(`fixed${Number(i) + 1}`);
            itemArr[index] = dummyNameRuleList['fixed'][i];
        }
        for (const i in itemArr) {
            if (itemArr[i] === nameTag.DATE) {
                itemArr[i] = locale.t(locale.keys.fileName.date.index);
                setDateFlag(true);
            }
            if (itemArr[i] === nameTag.USER) {
                itemArr[i] = locale.t(locale.keys.fileName.user.index);
                setUserFlag(true);
            }
            if (itemArr[i] === nameTag.FILE) {
                itemArr[i] = locale.t(locale.keys.fileName.anyCharater.index);
                setAnyCharaterFlag(true);
            }
        }
        setItemList(itemArr);
        let folderList = [...itemArr];
        folderList = folderList.filter((it: string) => regexFolder.test(it));
        setIsFolderFull(folderList.length === FOLDER_SIZE);
    };

    const handleClickDateButton = () => {
        const dataList = [...itemList];
        dataList.push(locale.t(locale.keys.fileName.date.index));
        setDateFormat(DateFormatValue.Date1);
        setItemList(dataList);
        setDateFlag(true);
        setIsEdit(true);
        setIsYearSelected(true);
        setIsMonthSelected(true);
        setIsDaySelected(true);
        setIsHourSelected(true);
    };

    const handleClickYearButton = () => {
        const value = !isYearSelected;
        const format = dateFormat;
        let newFormat = '';
        if (isYearSelected) {
            newFormat = format.replace('y|', '');
        }
        if (!isYearSelected) {
            newFormat = `y|${format}`;
        }
        setDateFormat(getEnumData(newFormat, DateFormatValue));
        setIsYearSelected(value);
        setIsEdit(true);
    };

    const handleClickMonthButton = () => {
        const value = !isMonthSelected;
        const format = dateFormat;
        let newFormat = '';
        if (isMonthSelected && format.includes('y')) {
            newFormat = format.replace('|M', '');
        }
        if (isMonthSelected && !format.includes('y')) {
            newFormat = format.replace('M|', '');
        }
        if (!isMonthSelected && !format.includes('y')) {
            newFormat = `M|${format}`;
        }
        if (!isMonthSelected && format === 'y') {
            newFormat = `${format}|M`;
        }
        if (!isMonthSelected && format.includes('y') && format !== 'y') {
            newFormat = format.replace('y|', 'y|M|');
        }
        newFormat = newFormat.replace('||', '|');
        setIsMonthSelected(value);
        setDateFormat(getEnumData(newFormat, DateFormatValue));
        setIsEdit(true);
    };

    const handleClickDayButton = () => {
        const value = !isDaySelected;
        const format = dateFormat;
        let newFormat = '';
        if (isDaySelected && (format.includes('y') || format.includes('M'))) {
            newFormat = format.replace('|d', '');
        }
        if (isDaySelected && format === 'd|Hms') {
            newFormat = format.replace('d|', '');
        }
        if (!isDaySelected && !format.includes('Hms')) {
            newFormat = `${format}|d`;
        }
        if (!isDaySelected && format === 'Hms') {
            newFormat = `d|${format}`;
        }
        if (!isDaySelected && format.includes('Hms') && format !== 'Hms') {
            newFormat = format.replace('|Hms', '|d|Hms');
        }
        newFormat = newFormat.replace('||', '|');
        setIsDaySelected(value);
        setDateFormat(getEnumData(newFormat, DateFormatValue));
        setIsEdit(true);
    };

    const handleInputDateButton = () => {
        switch (dateFormat) {
            case DateFormatValue.Date2:
                return locale.t(locale.keys.fileName.date2.index);
            case DateFormatValue.Date3:
                return locale.t(locale.keys.fileName.date3.index);
            case DateFormatValue.Date4:
                return locale.t(locale.keys.fileName.date4.index);
            case DateFormatValue.Date5:
                return locale.t(locale.keys.fileName.date5.index);
            case DateFormatValue.Date6:
                return locale.t(locale.keys.fileName.date6.index);
            case DateFormatValue.Date7:
                return locale.t(locale.keys.fileName.date7.index);
            case DateFormatValue.Date8:
                return locale.t(locale.keys.fileName.date8.index);
            case DateFormatValue.Date9:
                return locale.t(locale.keys.fileName.date9.index);
            case DateFormatValue.Date10:
                return locale.t(locale.keys.fileName.date10.index);
            case DateFormatValue.Date11:
                return locale.t(locale.keys.fileName.date11.index);
            case DateFormatValue.Date12:
                return locale.t(locale.keys.fileName.date12.index);
            case DateFormatValue.Date13:
                return locale.t(locale.keys.fileName.date13.index);
            case DateFormatValue.Date14:
                return locale.t(locale.keys.fileName.date14.index);
            case DateFormatValue.Date15:
                return locale.t(locale.keys.fileName.date15.index);
            default:
                return locale.t(locale.keys.fileName.date1.index);
        }
    };

    const handleClickTimeButton = () => {
        const value = !isHourSelected;
        const format = dateFormat;
        let newFormat = '';
        if (isHourSelected) {
            newFormat = format.replace('|Hms', '');
        }
        if (!isHourSelected) {
            newFormat = `${format}|Hms`;
        }
        setDateFormat(getEnumData(newFormat, DateFormatValue));
        setIsHourSelected(value);
        setIsEdit(true);
    };

    const handleClickUserButton = () => {
        const dataList = [...itemList];
        dataList.push(locale.t(locale.keys.fileName.user.index));
        setItemList(dataList);
        setUserFlag(true);
        setIsEdit(true);
    };

    const handleClickAnyCharaterButton = () => {
        const dataList = [...itemList];
        dataList.push(locale.t(locale.keys.fileName.anyCharater.index));
        setItemList(dataList);
        setAnyCharaterFlag(true);
        setIsEdit(true);
    };

    const handleClickFolderButton = (folderNo: number) => {
        const dataList = [...itemList];
        const folderList = [...folderNumber];
        if (folderNo < 5) {
            dataList.push(locale.t(locale.keys.fileName.folder.index, { number: folderNo }));
            folderList.push(folderNo);
            setItemList(dataList);
            setFolderFlag(true);
            setIsEdit(true);
            setFolderNumber(folderList);
            return;
        }
        switch (itemSelected < 0) {
            case true:
                for (let i = 5; i <= FOLDER_SIZE; i += 1) {
                    if (!folderList.includes(i)) {
                        dataList.push(locale.t(locale.keys.fileName.folder.index, { number: i }));
                        folderList.push(i);
                        setItemList(dataList);
                        setFolderFlag(true);
                        setIsEdit(true);
                        setFolderNumber(folderList);
                        return;
                    }
                }
                break;
            case false:
                let numberValue = +dataList[itemSelected].split(locale.t(locale.keys.fileName.folderLabel.index))[1];
                const index = folderList.indexOf(numberValue);
                while (folderList.includes(numberValue)) {
                    numberValue += 1;
                }
                if (numberValue > 20) {
                    return;
                }
                folderList.splice(index, 1, numberValue);
                dataList[itemSelected] = locale.t(locale.keys.fileName.folder.index, { number: numberValue });
                setItemList(dataList);
                setFolderFlag(true);
                setIsEdit(true);
                setFolderNumber(folderList);
                return;
        }
    };

    const handleDisableFolder = (folderNo: number) => {
        const folderList = [...folderNumber];
        const full5: number[] = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        const full = full5.every((number) => folderList.includes(number));
        if (folderNo < 5 && folderList.includes(folderNo)) {
            return true;
        }
        if (folderNo >= 5 && (folderList.length >= FOLDER_SIZE || full)) {
            return true;
        }
        return false;
    };

    const handleChangeSwitch = () => {
        const check = !isLock;
        setIsLock(check);
        setIsEdit(true);
    };

    const getNameRule = async (endpointId: string) => {
        try {
            const dataResponse = await fileNameModel.getNameRule(endpointId, appContainer.values.authorizationCode);
            if (!dataResponse) {
                throw new Error('force to fail');
            }
            const dateArr = dataResponse['dateFormat'].split('|');
            if (dateArr.length === 0) {
                setDateFormat(DateFormatValue.Date1);
            } else {
                setDateFormat(getEnumData(dateArr.join('|'), DateFormatValue));
            }
            if (dateArr.includes('y')) {
                setIsYearSelected(true);
            }
            if (dateArr.includes('M')) {
                setIsMonthSelected(true);
            }
            if (dateArr.includes('d')) {
                setIsDaySelected(true);
            }
            if (dateArr.includes('Hms')) {
                setIsHourSelected(true);
            }
            setCanEmpty(dataResponse['fileOptional']);
            const itemListString = dataResponse['itemList'];
            setSeparatorSelected(dataResponse['separator']);
            if (dataResponse['separator'] === '') {
                setSeparatorSelected('none');
            }

            // 日付セパレーターの取得
            // v1.9.0以降の機能で、旧バージョン(データがない)の場合はデフォルト値を設定する
            // APIでもデフォルト値は設定されるが画面でも念のため同様の処理を行う
            if (dataResponse.dateSeparator === undefined) {
                setDateSeparatorSelected(DEFAULT_DATE_SEPARATOR);
            } else if (dataResponse.dateSeparator === '') {
                // 「なし」の場合はnoneを設定する
                // MUIのコンポーネントが空文字を許容しないため
                setDateSeparatorSelected('none');
            } else {
                setDateSeparatorSelected(dataResponse.dateSeparator);
            }
            // 単語帳名称の取得
            setWordListName01(dataResponse['wordListName01']);
            setWordListName02(dataResponse['wordListName02']);
            // 単語帳リスト取得
            setWordList(dataResponse['wordList']);
            setWordList2(dataResponse['wordList02']);

            const itemArr: string[] = itemListString.split('|');
            for (const i in dataResponse['fixed']) {
                const index = itemArr.indexOf(`fixed${Number(i) + 1}`);
                itemArr[index] = dataResponse['fixed'][i];
            }
            const newItemArr = [];
            for (const i in itemArr) {
                if (itemArr[i] === nameTag.DATE) {
                    itemArr[i] = locale.t(locale.keys.fileName.date.index);
                    newItemArr.push(itemArr[i]);
                    setDateFlag(true);
                    // incase dateFormat != Hms but include Hms, example: y|M|Hms
                } else if (itemArr[i] === nameTag.USER) {
                    itemArr[i] = locale.t(locale.keys.fileName.user.index);
                    newItemArr.push(itemArr[i]);
                    setUserFlag(true);
                } else if (itemArr[i] === nameTag.FILE) {
                    itemArr[i] = locale.t(locale.keys.fileName.anyCharater.index);
                    newItemArr.push(itemArr[i]);
                    setAnyCharaterFlag(true);
                } else {
                    // in case folder
                    newItemArr.push(itemArr[i]);
                }
            }
            const listFolder = [...folderNumber];
            for (const i in newItemArr) {
                if (newItemArr[i].includes('folder')) {
                    const numberValue = +newItemArr[i].split('folder')[1];
                    listFolder.push(numberValue);
                }
            }
            const itemArray = newItemArr.map((item) => item.replace('folder', locale.t(locale.keys.fileName.folderLabel.index)));
            setItemList(itemArray);
            let folderList = [...itemArr];
            folderList = folderList.filter((it: string) => regexFolder.test(it));
            setFolderNumber(listFolder);
            setIsFolderFull(folderList.length === FOLDER_SIZE);
            setIsLock(dataResponse['lockEdit']);
            setIsOrgLock(dataResponse.orgLockEdit || false);
            setSequenceTypeSelected(dataResponse['sequenceType']);

            // workspacesからisCopiedがある場合セットする。
            // 共有Endpoitからコピーした場合設定されるフラグ。
            // ファイル名更新時には影響しない。
            if (dataResponse.isCopied) {
                setIsCopied(dataResponse['isCopied']);
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleClickSave = async () => {
        setIsEdit(false);
        try {
            let nameRule: string = '';
            const fixedList: string[] = [];
            for (let index = 0; index < itemList.length; index += 1) {
                if (itemList[index] === locale.t(locale.keys.fileName.date.index) && !nameRule.includes(nameTag.DATE)) {
                    nameRule += `|${nameTag.DATE}`;
                }
                if (itemList[index] === locale.t(locale.keys.fileName.user.index)) {
                    nameRule += `|${nameTag.USER}`;
                }
                if (itemList[index] === locale.t(locale.keys.fileName.anyCharater.index)) {
                    nameRule += `|${nameTag.FILE}`;
                }
                if (regexFolder.test(itemList[index])) {
                    const folderId = itemList[index].replace(locale.t(locale.keys.fileName.folderLabel.index), '');
                    nameRule += `|folder${folderId}`;
                }
                if (
                    !regexFolder.test(itemList[index]) &&
                    itemList[index] !== locale.t(locale.keys.fileName.anyCharater.index) &&
                    itemList[index] !== locale.t(locale.keys.fileName.user.index) &&
                    itemList[index] !== locale.t(locale.keys.fileName.time.index) &&
                    itemList[index] !== locale.t(locale.keys.fileName.date.index)
                ) {
                    fixedList.push(itemList[index]);
                    nameRule += `|${nameTag.FIXED}${fixedList.length}`;
                }
            }
            nameRule = nameRule.substr(1);
            let separator = separatorSelected;
            if (separator === 'none') {
                separator = '';
            }

            let dateSeparator = dateSeparatorSelected;
            if (dateSeparator === 'none') {
                dateSeparator = '';
            }

            const nameRuleObject = {
                wordList,
                wordList02,
                dateFormat,
                separator,
                dateSeparator,
                fileOptional: canEmpty,
                fixed: fixedList,
                itemList: nameRule,
                sequenceType: sequenceTypeSelected,
                lockEdit: isLock,
                wordListName01,
                wordListName02,
            };
            const dataResponse = await fileNameModel.updateNameRule(endpointId, nameRuleObject, appContainer.values.authorizationCode);
            if (!dataResponse) {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.error),
                    variant: Variants.error,
                });
            } else {
                appContainer.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.updated),
                    variant: Variants.success,
                });
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleClickChip = (key: number, value: string) => {
        const selectedItem = itemSelected;
        const numberValue = +value.split(locale.t(locale.keys.fileName.folderLabel.index))[1];
        if (numberValue < 5 || !value.includes(locale.t(locale.keys.fileName.folderLabel.index))) {
            return;
        }
        if (selectedItem === key) {
            setItemSelected(-1);
            return;
        }
        if (selectedItem !== key) {
            setItemSelected(key);
            return;
        }
    };

    const isButtonDisable = (name: string) => {
        switch (name) {
            case 'year':
                if (!dateFlag) return true;
                if (!isMonthSelected && !isDaySelected && !isHourSelected) return true;
                return false;
            case 'month':
                if (!dateFlag) return true;
                if (!isYearSelected && !isDaySelected && !isHourSelected) return true;
                return false;
            case 'day':
                if (!dateFlag) return true;
                if (!isYearSelected && !isMonthSelected && !isHourSelected) return true;
                return false;
            case 'hour':
                if (!dateFlag) return true;
                if (!isYearSelected && !isMonthSelected && !isDaySelected) return true;
                return false;
            default:
                return false;
        }
    };

    const getFileNameExample = () => {
        let exampleText = '';
        if (!itemList || !Array.isArray(itemList) || itemList.length === 0) {
            return exampleText;
        }
        let separator = separatorSelected;
        if (separator === 'none') {
            separator = '';
        }
        for (let index = 0; index < itemList.length; index += 1) {
            if (itemList[index] === locale.t(locale.keys.fileName.date.index)) {
                let tempDate = fileNameDateExample(dateSeparatorSelected, dateFormat, moment());
                exampleText += `${separator}${tempDate}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.user.index)) {
                exampleText += `${separator}${locale.t(locale.keys.fileName.user.index)}`;
            }
            if (itemList[index] === locale.t(locale.keys.fileName.anyCharater.index)) {
                exampleText += `${separator}${locale.t(locale.keys.fileName.anyText.index)}`;
            }
            if (regexFolder.test(itemList[index])) {
                exampleText += `${separator}${itemList[index].replace(' ', '')}`;
            }
            if (
                !regexFolder.test(itemList[index]) &&
                itemList[index] !== locale.t(locale.keys.fileName.anyCharater.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.user.index) &&
                itemList[index] !== locale.t(locale.keys.fileName.date.index)
            ) {
                exampleText += `${separator}${itemList[index]}`;
            }
        }
        exampleText += `.pdf`;
        if (separator) {
            exampleText = exampleText.substr(1);
        }

        return exampleText;
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const replaceWordbook = (words: string) => {
        handleAddWord(words, true);
    };
    const clearWordList = () => {
        setWordList(wordData);
        setIsEdit(true);
    };
    const clearWordList2 = () => {
        setWordList2(wordData);
        setIsEdit(true);
    };

    const isDateSelected = () => {
        if (itemList.includes(locale.t(locale.keys.fileName.date.index))) {
            return true;
        }
        return false;
    };

    return {
        ui,
        setUI,
        regexFolder,
        endpointId,
        setEndpointId,
        itemList,
        setItemList,
        isFolderFull,
        setIsFolderFull,
        setFolderFlag,
        separatorSelected,
        dateSeparatorSelected,
        canEmpty,
        wordList,
        wordList02,
        dateFormat,
        handleChangeSeparatorSelected,
        handleChangeDateSeparatorSelected,
        handleChangeCanEmpty,
        buttonDisabled,
        dateFlag,
        userFlag,
        anyCharaterFlag,
        folderFlag,
        loadDummyData,
        handleAddItem,
        handleDeleteItem,
        handleDeleteFileNameLimitErrorMessege,
        handleChangeWordListName01,
        handleChangeWordListName02,
        wordListName01,
        wordListName02,
        handleAddWord,
        handleAddWord2,
        handleDeleteWord,
        handleDeleteWord2,
        handleClickDateButton,
        handleClickTimeButton,
        handleClickUserButton,
        handleClickAnyCharaterButton,
        handleClickFolderButton,
        getNameRule,
        handleClickSave,
        getFileNameExample,
        errors,
        errorsName,
        isEdit,
        setIsEdit,
        handleDisableFolder,
        handleInputDateButton,
        itemSelected,
        setItemSelected,
        handleClickChip,
        isYearSelected,
        setIsYearSelected,
        isMonthSelected,
        setIsMonthSelected,
        isDaySelected,
        setIsDaySelected,
        handleClickYearButton,
        handleClickMonthButton,
        handleClickDayButton,
        setDateFormat,
        isHourSelected,
        setIsHourSelected,
        isButtonDisable,
        isLock,
        isOrgLock,
        setIsLock,
        sequenceTypeSelected,
        setSequenceTypeSelected,
        handleChangeSequenceTypeSelected,
        handleChangeSwitch,
        handleOpen,
        handleClose,
        open,
        replaceWordbook,
        clearWordList,
        clearWordList2,
        isCopied,
        fileNameErrorMessage,
        fileNameLimitErrorMessege,
        isDateSelected,
    };
};
export const FileNameContainer = createContainer(useFileNameContainer);

import React from 'react';
import { Button, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import { ColorLinearProgress } from '@/common/components/ui-state/LoadingState';
import locale from '@/common/utils/locale';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

// リダイレクト先の認可画面
const styles = (theme: Theme) =>
    createStyles({
        root: {
            gap: '8px',
            height: '100vh',
        },
        body: {
            display: 'flex',
            gap: '8px',
            width: '90%',
            height: '90vh',
            marginRight: '5%',
            marginLeft: '5%',
        },
        logo: {
            width: '100%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
        },
        title: {
            fontSize: 24,
            fontWeight: 'bold',
        },
        bodyText: {
            fontSize: 16,
            fontWeight: 'normal',
        },
        footer: {
            display: 'flex',
            justifyContent: 'right',
            flexGrow: 1,
            paddingBottom: '5vh',
            alignItems: 'end',
            gap: '8px',
        },
        authButton: {
            margin: theme.spacing.unit,
            fontSize: '13px',
            fontWeight: 'bold',
        },
    });

// ヘッダー、ボディ、ローディングの骨組み
interface BaseProps extends WithStyles<typeof styles> {
    children?: React.ReactNode;
    isLoading?: boolean;
}
const Base: React.FC<BaseProps> = (props) => {
    return (
        <Grid className={props.classes.root}>
            <Grid item className={props.classes.logo}>
                <img alt="header-logo" width="100%" height="100%" src="/assets/images/logo/brand.svg" />
            </Grid>
            {props.isLoading ? (
                <Grid data-testid={'loading'}>
                    <ColorLinearProgress />
                </Grid>
            ) : (
                <Grid container direction="column" className={props.classes.body}>
                    {props.children}
                </Grid>
            )}
        </Grid>
    );
};
export const StylesBase = withStyles(styles)(Base);

// 認可の結果画面
interface ResultProps extends WithStyles<typeof styles> {
    title: string;
    message: string;
}
const Result: React.FC<ResultProps> = (props) => {
    const setMultiLineText = (str: string): JSX.Element[] => {
        const splite = getSplitNewLineString(str);
        const components: JSX.Element[] = [];
        for (let text of splite) {
            components.push(<Typography className={props.classes.bodyText}>{text}</Typography>);
        }
        return components;
    };

    return (
        <StylesBase>
            <Grid item>
                <Typography className={props.classes.title}>{props.title}</Typography>
            </Grid>
            <Grid item>{setMultiLineText(props.message)}</Grid>
            <Grid item className={props.classes.footer}>
                <Button
                    variant="outlined"
                    // なぜかcreateStylesが反映されないのでここで上書きする
                    style={{
                        color: 'gray',
                        borderColor: 'gray',
                    }}
                    onClick={() => {
                        window.close();
                    }}
                >
                    {locale.t(locale.keys.cloudConnectionAuth.component.close)}
                </Button>
            </Grid>
        </StylesBase>
    );
};
export const StylesResult = withStyles(styles)(Result);

// ローディング画面
interface LoadingProps extends WithStyles<typeof styles> {}
const Loading: React.FC<LoadingProps> = () => {
    return <StylesBase isLoading={true} />;
};
export const StylesLoading = withStyles(styles)(Loading);

// 認可URL取得後の画面（自動的にリダイレクトされるので、表示されることはない）
interface CloudConnectionProps extends WithStyles<typeof styles> {
    // redirectUrl: string;
    onClickRetryAuth: () => void;
}
const CloudConnection: React.FC<CloudConnectionProps> = (props) => {
    const setMultiLineText = (str: string): JSX.Element[] => {
        const splite = getSplitNewLineString(str);
        const components: JSX.Element[] = [];
        for (let text of splite) {
            components.push(<Typography className={props.classes.bodyText}>{text}</Typography>);
        }
        return components;
    };

    return (
        <StylesBase>
            <Grid item>
                <Typography className={props.classes.title}>{locale.t(locale.keys.cloudConnectionAuth.title.cloudConnection)}</Typography>
            </Grid>
            <Grid item>{setMultiLineText(locale.t(locale.keys.cloudConnectionAuth.message.cloudConnection))}</Grid>
            <Grid item className={props.classes.footer}>
                <Button
                    variant="outlined"
                    // なぜかcreateStylesが反映されないのでここで上書きする
                    style={{
                        color: 'gray',
                        borderColor: 'gray',
                    }}
                    onClick={() => {
                        window.close();
                    }}
                >
                    {locale.t(locale.keys.cloudConnectionAuth.component.cancel)}
                </Button>
                <Button
                    variant="outlined"
                    style={{
                        color: 'white',
                        borderColor: '#ff3d00',
                        backgroundColor: '#ff3d00',
                    }}
                    onClick={props.onClickRetryAuth}
                >
                    {locale.t(locale.keys.cloudConnectionAuth.component.auth)}
                </Button>
            </Grid>
        </StylesBase>
    );
};
export const StylesCloudConnection = withStyles(styles)(CloudConnection);

// 上記以外の場合の画面（無効なページ）
interface ForbiddenProps extends WithStyles<typeof styles> {}
const Forbidden: React.FC<ForbiddenProps> = (props) => {
    return (
        <StylesBase>
            <Grid item>
                <Typography className={props.classes.title}>{locale.t(locale.keys.cloudConnectionAuth.title.forbidden)}</Typography>
            </Grid>
            <Grid item>
                <Typography className={props.classes.bodyText}>{locale.t(locale.keys.cloudConnectionAuth.message.error.unknown)}</Typography>
            </Grid>
        </StylesBase>
    );
};
export const StylesForbidden = withStyles(styles)(Forbidden);
